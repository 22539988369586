export var DeviceWidth;
(function (DeviceWidth) {
    DeviceWidth[DeviceWidth["Small"] = 576] = "Small";
    DeviceWidth[DeviceWidth["Medium"] = 768] = "Medium";
    DeviceWidth[DeviceWidth["Large"] = 992] = "Large";
    DeviceWidth[DeviceWidth["ExtraLarge"] = 1200] = "ExtraLarge";
})(DeviceWidth || (DeviceWidth = {}));
var MenuDataItemType;
(function (MenuDataItemType) {
    MenuDataItemType["Department"] = "department";
    MenuDataItemType["Category"] = "category";
})(MenuDataItemType || (MenuDataItemType = {}));
export var SearchRecommendationType;
(function (SearchRecommendationType) {
    SearchRecommendationType["Warehouse"] = "WAREHOUSE";
    SearchRecommendationType["Webshop"] = "WEBSHOP";
    SearchRecommendationType["QuerySuggestion"] = "QUERY_SUGGESTION";
})(SearchRecommendationType || (SearchRecommendationType = {}));
export var OnSiteAttribution;
(function (OnSiteAttribution) {
    OnSiteAttribution["FRUUGO"] = "self";
    OnSiteAttribution["BYTEPLUS"] = "bp";
})(OnSiteAttribution || (OnSiteAttribution = {}));
